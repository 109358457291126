
import React from 'react'
import Container from '../components/Container'
import {graphql, useStaticQuery} from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'

import {css} from '@emotion/core'
import {useTheme} from '../components/Theming'



const About = () => {
  const theme=useTheme()
    const data = useStaticQuery(graphql`
    
    query {
        site {
          ...site
          siteMetadata {
            title
          }
        }
     
    }
    `)
    return(
        <Layout site={data.site} >
         
         <Hero 
          titel="Aktuell..."
          text=""
         />
     
        <Container>
          
          <h1 css={css`
            font-size: ${theme.fontsizes.great};
            @media(max-width: 500px){
              font-size: ${theme.fontsizes.small};
           
            }
          `}>
        Markt 1
     </h1>
     <p>verantwortlich:<br/>Verschönerungsverein</p>
     
    
        </Container>
        </Layout>
     
    )
}

export default About


