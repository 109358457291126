
import React from 'react'

import { css } from '@emotion/core'
//import {navigate} from 'gatsby-link'
//import { useTheme } from 'components/Theming'
import Container from 'components/Container'

import { bpMaxSM } from '../lib/breakpoints'
import Link from 'components/Link'






const Hero = ({titel,text,info,publish="true",background,url,aurl,color,bild}) => {
  console.log(titel)
 if(bild){
  background=`url('${bild}')`

  url=`${url}`
 }
   
  //const theme = useTheme()
 // if(publish)
  return (
 <div
 css={css`
   width:48%;
  position:relative;
 
  margin:1%;
  
   @media(max-width:696px){
     width:98%;

   }
 `}
 >
          <h2 
          css={css`
          font-size: 1.0rem;
          
          letterSpacing:'.06rem',
            color: black;
            position: relative;
            z-index: 5;
           padding:4px 0;
           
            margin-top:5px;
          
            font-weight: 500;
            text-transform: uppercase;
            
              

            }
          `}
        >
      
          {titel}
        
        </h2>
    {/* <div
    role="button"
    tabIndex="0"
      
      css={css`
      border-top:1px solid blue;
        height:75%;
        color: ${color};
        width: 100%;
       position:relative;
       margin-top:-18px;
       z-index:134;
        background: ${background};

        background-repeat: no-repeat;
        background-size: cover;
        padding: 10px 0 30px 0;
        display: flex;
        
        min-height:216px;
        max-height:216px; 

        min-width:48%;
        margin-left:0%;
        margin-right:0%;
        margin:1% auto 30px auto;
        @media(max-width:696px){
         margin:1%;
          min-height:190px;
          max-height:190px; 
          width:100%;
        }
       
       
      `}
    > */}
      <Link sx={{outline:0}} to={url}>
      <Container
        
        css={css`
          background: ${background};
          background-repeat: no-repeat;
        background-size: cover;
         
          display: flex;
          flex-direction: column;
          padding:0 10px;
          color:${color};
          min-height:220px;
          ${bpMaxSM} {
            
            min-width:98%;
            max-height:170px; 
            min-height:170px;
          }
         
        `}
      >



 
       
  <span
   css={css`
   font-size:1rem;
   padding-top:0;
   @media(max-width:774px) {
    font-size: .9rem; 
    line-height: 1.1rem;
   
  }
  @media(min-width: 768px){
   
     padding-top:20px;
     font-size:1.1rem;
  }
     `}

  >{text? text:""} </span>   
 <span
   css={css`
   font-size: .9rem;
   @media(max-width:375px) {
    font-size: .7rem;
    line-height: 1.1rem;
  }
     `}

  >{info? info:""} </span> 

      </Container>
      </Link>
    </div>
    //</div>
  )
  // else {
  //   return ""
  // }
}
export default Hero
